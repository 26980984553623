import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';

import { SearchFilterComponent } from './search-filter.component';
import { SortFilterComponent } from './sort-filter.component';
import { IndividualComponent } from './individual.component';
import { PagenationComponent } from './pagenation.component';

import { SearchFilterService } from './search-filter.service';
import { PagenationService } from './pagenation.service';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { jaLocale } from 'ngx-bootstrap/locale';

import { ButtonModule, InputTextModule } from 'primeng/primeng';
// import { ProgressSpinnerModule} from 'primeng/progressspinner';
import { SearchFilterAdminComponent } from './search-filter-admin.component';
import { ConvertToJsonPipe } from './convert-to-json.pipe';
import { DateParsePipe } from './date-parse.pipe';
import { NewBadgeComponent } from './new-badge.component';
// import { CalendarModule } from 'primeng/calendar';
import { CategoryPipe } from './category.pipe';
import { MomentPipe } from './moment.pipe';


defineLocale('ja', jaLocale);


@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ButtonModule,
      RouterModule,
      InputTextModule,
      BsDatepickerModule.forRoot()
   ],
   exports: [
      FormsModule,
      SearchFilterComponent,
      SearchFilterAdminComponent,
      SortFilterComponent,
      IndividualComponent,
      ButtonModule,
      InputTextModule,
      PagenationComponent,
      ConvertToJsonPipe,
      DateParsePipe,
      NewBadgeComponent,
      CategoryPipe,
      MomentPipe
   ],
   declarations: [
      SearchFilterComponent,
      SearchFilterAdminComponent,
      SortFilterComponent,
      IndividualComponent,
      PagenationComponent,
      ConvertToJsonPipe,
      DateParsePipe,
      NewBadgeComponent,
      CategoryPipe,
      MomentPipe
   ],
   providers: [
      SearchFilterService,
      DatePipe,
      PagenationService
   ]
})
export class SharedModule { }
