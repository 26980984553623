import { Component } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showHowto: boolean = false;

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) { }

  onActivate(component) {
    if (!component.compoName || component.compoName !== 'login') {
      this.showHowto = true;
    } else {
      this.showHowto = false;
    }
    // this.showHowto = component.compoName && (component.compoName === 'login');
  }

}
