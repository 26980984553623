
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


import { IUser, IEntry } from './user';
// import { Setting } from '../setting';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {

  // 認証用のAPIアドレス(現状はダミー)
  private baseUrl: string = environment.initUrl + 'api/authenticate';
  private entryUrl: string = environment.initUrl + 'api/entry';
  private passwdUrl: string = environment.initUrl + 'api/forgot';

  // ユーザーのステータス（各コンポーネントで呼び出される）
  public token: string;
  public currentUserId: number;
  public currentUserName: string;
  public currentUserEmail: string;
  public currentGroupName: string;
  public currentGroupCode: string;
  public currentFlag: string;
  public currentServiceType: string;
  // KBVかを判断
  public kbvFlag: boolean = false;
  // ヤクルトレディかを判断
  public ladyFlag: boolean = false;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.currentUserId = currentUser && currentUser.user_id;
    this.currentUserName = currentUser && currentUser.user_name;
    this.currentUserEmail = currentUser && currentUser.user_email;
    this.currentGroupName = currentUser && currentUser.group_name;
    this.currentGroupCode = currentUser && currentUser.group_belong_code;
    this.currentFlag = currentUser && currentUser.identity_flag;
    this.currentServiceType = currentUser && currentUser.service_type;
    this.currentFlag === "5" ? this.kbvFlag = true : this.kbvFlag = false;
    this.currentFlag === "7" ? this.ladyFlag = true : this.ladyFlag = false;
  }

  login(userID: string, password: string): Observable<IUser> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post<IUser>(this.baseUrl, JSON.stringify({ 'username': userID, 'password': password }), { headers: headers }).pipe(
      map(response => {
        // login successful if there's a jwt token in the response
        this.token = response.token;
        this.currentUserId = response.user_id;
        this.currentUserName = response.user_name;
        this.currentUserEmail = response.user_email;
        this.currentGroupName = response.group_name;
        this.currentGroupCode = response.group_belong_code;
        this.currentFlag = response.identity_flag;
        this.currentServiceType = response.service_type;
        this.currentFlag === "5" ? this.kbvFlag = true : this.kbvFlag = false;
        this.currentFlag === "7" ? this.ladyFlag = true : this.ladyFlag = false;
        // console.log(JSON.stringify(response));

        if (this.token && this.currentServiceType === "2") {

          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser',
            JSON.stringify({
              token: this.token,
              user_id: this.currentUserId,
              user_name: this.currentUserName,
              user_email: this.currentUserEmail,
              group_name: this.currentGroupName,
              group_belong_code: this.currentGroupCode,
              identity_flag: this.currentFlag,
              service_type: this.currentServiceType
            }));

          // return true to indicate successful login
          return response;
        } else {
        // return false to indicate failed login
        // return false;
        this.logout();
         }
      })
      // , tap((response) => console.log(JSON.stringify(response)))
      );
  }

  loggedIn() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.service_type === "2") {
      return true;
    }
    return false;
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;
    this.currentUserId = null;
    this.currentUserName = null;
    this.currentUserEmail = null;
    this.currentGroupName = null;
    this.currentFlag = null;
    localStorage.removeItem('currentUser');
  }

  // 新規登録
  entry(entry: IEntry): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.entryUrl, entry, { headers: headers }).pipe(
      // tap(() => console.log('Entry: ')),
      catchError(this.handleError));
  }

  // パスワードを忘れた方へ
  getPasswd(email: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.passwdUrl, email, { headers: headers }).pipe(
      // .do(() => console.log('Entry: '))
      catchError(this.handleError));

  }

  // エラー処理
  private handleError(error: HttpErrorResponse) {
    console.error(error.message);
    return observableThrowError(error.message);
  }

}
