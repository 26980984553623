import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-new-badge',
  templateUrl: './new-badge.component.html',
  styleUrls: ['./new-badge.component.scss']
})
export class NewBadgeComponent implements OnChanges {

  public sTime: Date;
  public fTime: Date;

  @Input() firstTime: string;
  @Input() secondTime: string;

  public newBadgeFlag: boolean = true;

  constructor() { }

  ngOnChanges(): void {
    if (this.firstTime) {
      this.fTime = new Date(this.firstTime.replace(/\s/, 'T'));
    }
    if (this.secondTime) {
      this.sTime = new Date(this.secondTime.replace(/\s/, 'T'));
    }
    console.log("timeB: " + this.firstTime);
    // console.log("time: " + fTime);
    if (this.fTime && this.sTime) {
      this.fTime < this.sTime ? this.newBadgeFlag = true : this.newBadgeFlag = false;
    }
  }

}
