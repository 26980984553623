import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Setting } from '../../setting';
import { PagenationService } from './pagenation.service';

@Component({
    selector: 'pagination',
    templateUrl: './pagenation.component.html',
  styleUrls: ['./pagenation.component.scss']
})
export class PagenationComponent implements OnChanges {

    public totalPages: number;
    public pages: number[] = [];
    public currentPage: number = 1;
    public isVisible: boolean = false;
    public previousEnabled: boolean = false;
    public nextEnabled: boolean = true;

    // pager object
    public pager: any = {};

    @Output() public pageChanged: EventEmitter<number> = new EventEmitter();

    private pagerTotalItems: number;
    private pagerPageSize: number = Setting.PAGE_SIZE;

    @Input() get totalItems(): number {
        return this.pagerTotalItems;
    }
    ngOnChanges(changes: any) {
        // 検索条件が変わった時、前回検索結果がゼロの場合、ページネーションが表示されない問題を解決するためのコードを書く
        console.log("change: " + this.pagerTotalItems);
        // this.setPage(1);
      }

    set totalItems(itemCount: number) {
        this.pagerTotalItems = itemCount;
        // this.update();
        // initialize to page 1
        this.setPage(1);
        console.log("change2: " + this.pagerTotalItems);
    }

    constructor(private pagerService: PagenationService) {
     }


    setPage(page: number, event?: MouseEvent) {
      if (page < 1 || page > this.pager.totalPages) {
        // console.log("change4: " + this.pagerTotalItems);
          return;
      }

      // get pager object from service
      this.pager = this.pagerService.getPager(this.pagerTotalItems, page);
      this.changePage(page, event);
  }

    /**
     * ページ番号押下
     *
     * @param page ページ番号
     * @param event
     */
    public changePage(page: number, event?: MouseEvent) {
        if (event) {
            event.preventDefault();
        }

        // 現在表示ページと同じであれば何もしない
        if (this.currentPage === page) {
            return;
        }

        // 指定ページに遷移
        this.currentPage = page;
        this.previousEnabled = this.currentPage > 1;
        this.nextEnabled = this.currentPage < this.totalPages;
        this.pageChanged.emit(page);
    }
}
