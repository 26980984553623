import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { AuthenticationService } from '../../auth/auth.service';

// import objectFitImages from 'object-fit-images';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {

  // get userName(): string {
  //   return this.authenticationService.currentUserName;
  // }
  // get groupName(): string {
  //   return this.authenticationService.currentGroupName;
  // }
  // get identityFlag(): string {
  //   return this.authenticationService.currentFlag;
  // }
  // get userId(): number {
  //   return this.authenticationService.currentUserId;
  // }

  public userName: string;
  public groupName: string;
  public groupNameCode: string;
  public identityFlag: string;
  public userId: number;
  // ヤクルトレディかを判断
  public ladyFlag: boolean = false;

  // 写真のPATH
  public imgUrl: string = environment.imgUrl;
  // 写真のキャッシュ対策
  public noCache: string = new Date() + "";
  // アバターの写真path
  public avatarPath: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = currentUser.user_name;
    this.groupName = currentUser.group_name;
    this.groupNameCode = currentUser.group_belong_code;
    this.identityFlag = currentUser.identity_flag;
    this.userId = currentUser.user_id;
    // this.avatarPath = ('000000' + this.userId).slice(-7);
  }

  ngOnInit() {
    // this.userName = this.authenticationService.currentUserName;
    // this.groupName = this.authenticationService.currentGroupName;
    // this.identityFlag = this.authenticationService.currentFlag;
    // this.userId = this.authenticationService.currentUserId;
    // ヤクルトレディ判定
    this.ladyFlag = this.authenticationService.ladyFlag;
    // アバター写真パス
    this.avatarPath = ('000000' + this.userId).slice(-7);
    // console.log('User:' + this.userName);
    // console.log('Grop:' + this.groupName);
    // console.log('GropCode:' + this.groupNameCode);
    // console.log('Flag:' + this.identityFlag);
  }

  logOut(): void {
    this.authenticationService.logout();
    // localStorage.clear();
    this.router.navigate(['/login']);
    // IE11用の写真トリミング
    // objectFitImages();
  }

}
