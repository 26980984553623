import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import * as _ from 'underscore';

import { AuthenticationService } from '../auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./login.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public model: any = {};
  public error = '';

    // 各エラーオブジェクト
    public errUser_id: any;
    public errUser_id2: any;

    // 完了フラグ
    public sendPasswordComplete: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  getPassword(): void {
    this.authenticationService.getPasswd(this.model)
            .subscribe(result => {
                if (result.status === 0) {
                    this.sendPasswordComplete = true;
                } else {
                    const errorObject = result.errs;
                    this.errUser_id = _.where(errorObject, { fieldname: "user_id" });
                    this.errUser_id2 = _.where(errorObject, { fieldname: "user_id2" });
                }
            });

  }
}
