import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth/auth.service';

@Component({
  selector: 'app-register-completion',
  templateUrl: './register-completion.component.html',
  styleUrls: ['./login.component.scss']
})
export class RegisterCompletionComponent implements OnInit {

  // KBVかを判断
 //  public kbvFlag: boolean = false;
  // yakultレディかを判断
  public ladyFlag: boolean = false;

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    // this.kbvFlag = this.authenticationService.kbvFlag;
    this.ladyFlag = this.authenticationService.ladyFlag;
  }

}
