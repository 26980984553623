export class Setting {

    // ローカル用
    // public static initUrl = "https://e-ne-develop.com/";
    // public static imgUrl = "https://e-ne-develop.com/";

    // サーバ用
    // public static initUrl = "/";
    // public static imgUrl = "/";

    // 投稿数（投稿一覧取得数）
    public static pageNum = '20';
    public static pageNumMuch = '40';

    // 投稿開始日
    public static startDate = '2015/04/01';

    // 管理画面で表示する投稿数
    public static PAGE_SIZE = 20;

    // テーマ
    public static theme = {
        'マーケット攻略': '1',
        '組織強化': '9',
        '業務改善': '10',
        '地域社会貢献PR': '11',
        'KBC協業': '12',
        'お知らせ': '13',
        'その他': '999'
    };

    // マーケット攻略Option
    public static marcket = {
        '量販店': '1',
        '病院・老健': '2',
        '学校': '3',
        '交通': '4',
        '事業所': '5',
        'ホール': '6',
        'その他': '8'
    };

    // テーマのレスポンス
    public static theme_res = {
        'マーケット攻略【量販店】': '1',
        'マーケット攻略【病院・老健】': '2',
        'マーケット攻略【学校】': '3',
        'マーケット攻略【交通】': '4',
        'マーケット攻略【事業所】': '5',
        'マーケット攻略【ホール】': '6',
        'マーケット攻略【その他】': '8',
        '組織強化': '9',
        '業務改善': '10',
        '地域社会貢献PR': '11',
        'KBC協業': '12',
        'お知らせ': '13',
        'その他': '999'
    };

    // チャネルOption
    public static channel = {
        '給食': '1',
        '自販機': '2',
        '手売り': '3',
        'その他': '4'
    };

     // エリアのコンテンツOption
     public static contents = {
        '健康教室・健康セミナー': '1',
        '栄養相談会': '2',
        '出前授業': '3',
        '工場・研究所見学会': '4',
        '美容教室': '5',
        '健康フォーラム': '6',
        'オピニオンリーダー向け飲用体感活動': '7',
        '店頭価値普及活動': '8',
        'その他': '9'
    };

    // エリアの対象者Option
    public static target = {
        '愛飲者': '101',
        '病院': '102',
        '栄養士会': '103',
        '医師会': '104',
        '歯科医師会': '105',
        '自治会': '106',
        '商工会': '107',
        '子供会': '108',
        '老人会': '109',
        '婦人会': '110',
        '老健施設': '111',
        '企業': '112',
        '教育機関': '113',
        '行政': '114',
        'その他': '115'
    };
    // エリアの実施場所Option
    public static scene = {
        '愛飲者宅': '101',
        '病院': '102',
        '老健施設': '103',
        '企業・事業所': '104',
        '教育機関': '105',
        '行政': '106',
        'センター': '107',
        '量販店': '108',
        '交通': '109',
        'パチンコホール': '111',
        '研究所・工場': '110',
        'その他': '112'
    };
    // エリアの人数Option
    public static numberOfPeople = {
        '1～5人': '1',
        '6人～20人': '2',
        '20人～50人': '3',
        '51人～100人': '4',
        '101人～': '5'
    };

    // エリアの活動成果Option
    public static result = {
        '新規顧客': '1',
        '既存取引拡大': '2',
        '見込みづくり': '3',
        '関係値強化': '4',
        '地域貢献': '5',
        '企業ＰＲ': '6',
        'その他': '999'
    };

    // 地区
    public static region = {
        '北海道支店': '1',
        '東日本支店': '2',
        '首都圏支店': '3',
        '中日本支店': '4',
        '西日本支店': '5',
        '本店': '6',
        '北海道地区': '7',
        '東北地区': '8',
        '中部北関東地区': '9',
        '首都圏地区': '10',
        '東海北陸地区': '11',
        '近畿地区': '12',
        '中四国地区': '13',
        '九州地区': '14'
        // ,'直販営業部': '6'
    };

    // aValue除外販売店
    public static exception = ['testヤクルト'];

    // FloalaEditer License Keys エディターのライセンスキー
    public static editorLicense = 'dC-22lzaA3tbhpcA6by==';
}
