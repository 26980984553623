import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// import { IPostsParams } from './posts/posts-params';

@Injectable()
export class TransferParamsService {

   // 一覧ページ用
   public postParams: any = {};

   // 管理ページ用
   public postAdminParams: any = {};

  constructor() { }

  // 一覧ページ用
  private paramsDataSource = new Subject<any>();
  // 管理ページ用
  private paramsAdminDataSource = new Subject<any>();

  // Observable stream（一覧ページ用）
  public paramsData$ = this.paramsDataSource.asObservable();
  // Observable stream（管理ページ用）
  public paramsAdminData$ = this.paramsAdminDataSource.asObservable();

  // パラメータを送る（一覧ページ用）
  transferParamsToCompo(params: any): void {
    this.paramsDataSource.next(params);
     this.postParams = params;
  }
  // パラメータを送る（管理ページ用）
  transferAdminParamsToCompo(params: any): void {
    this.paramsAdminDataSource.next(params);
    this.postAdminParams = params;
  }

}
