
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { AuthenticationService } from '../../auth/auth.service';

@Injectable()
export class SearchFilterService {

  private baseUrl: string = environment.initUrl + "api/post/get_query_params";
  private contestUrl: string = environment.initUrl + "api/post/get_contests";

  private baseAdminUrl: string = environment.initUrl + "api/admin/post/get_query_params";
  private contestAdminUrl: string = environment.initUrl + "api/admin/post/get_contests";

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  // 検索条件初期値の取得
  public getQueryParams(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.authenticationService.token);
    return this.http.get<any>(this.baseUrl + '?service_type=2', { headers: headers }).pipe(
      // .do(data => console.log('getPosts: ' + JSON.stringify(data)))
      catchError(this.handleError));
  }

// コンテスト一覧の取得
  public getContests(year: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.authenticationService.token);
    const url = `${this.contestUrl}?year=${year}&service_type=2`;
    return this.http.get<any>(url, { headers: headers }).pipe(
      // .do(data => console.log('contest: ' + JSON.stringify(data)))
      catchError(this.handleError));
  }

    // 検索条件初期値の取得（管理ページ）
    public getAdminQueryParams(): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.authenticationService.token);
      return this.http.get<any>(this.baseAdminUrl, { headers: headers }).pipe(
        // .do(data => console.log('getPosts: ' + JSON.stringify(data)))
        catchError(this.handleError));
    }

  // コンテスト一覧の取得（管理ページ）
    public getAdminContests(year: number): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + this.authenticationService.token);
      const url = `${this.contestAdminUrl}?year=${year}&service_type=2`;
      return this.http.get<any>(url, { headers: headers }).pipe(
        // .do(data => console.log('contest: ' + JSON.stringify(data)))
        catchError(this.handleError));
    }

  private handleError(error: HttpErrorResponse) {
    console.error(error.message);
    return observableThrowError(error.message);
  }

}
