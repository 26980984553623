import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import * as _ from 'underscore';

import { AuthenticationService } from '../auth/auth.service';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['./login.component.scss']
})
export class RegisterComponent implements OnInit {
    public model: any = {};
    // loading = false;
    public error = '';

    // 各エラーオブジェクト
    public errUser_id: any;
    public errUser_id2: any;
    public errPassword: any;
    public errPassword2: any;
    public errUserName: any;
    public errCode: any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) { }

    ngOnInit() {
    }

    entry(): void {
        this.authenticationService.entry(this.model)
            .subscribe(result => {
                if (result.status === 0) {
                    this.login();
                } else {
                    const errorObject = result.errs;
                    this.errUser_id = _.where(errorObject, { fieldname: "user_id" });
                    this.errUser_id2 = _.where(errorObject, { fieldname: "user_id2" });
                    this.errPassword = _.where(errorObject, { fieldname: "password" });
                    this.errPassword2 = _.where(errorObject, { fieldname: "password2" });
                    this.errUserName = _.where(errorObject, { fieldname: "user_name" });
                    this.errCode = _.where(errorObject, { fieldname: "code" });
                }
            });
    }

    login() {
        const userName = this.model.user_id;
        const password = this.model.password;
        this.authenticationService.login(userName, password)
            .subscribe(result => {
                if (result) {
                    this.router.navigate(['/completion']);

                } else {
                    this.error = 'Username or password is incorrect';
                }
            });
    }
}
