import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import * as _ from 'underscore';

import { SearchFilterService } from './search-filter.service';
import { TransferParamsService } from '../transfer-params.service';
import { DatePipe } from '@angular/common';

import { Setting } from '../../setting';

@Component({
  selector: 'app-search-filter-admin',
  templateUrl: './search-filter-admin.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterAdminComponent implements OnInit {

  // カレントユーザーのステータス
  public currentStatus: string;
  public errorMessage: string;
  public contestList: any[];
  public areaList: any[];
  public groups: any[];
  public areaGroups: any[] = [];
  public postParams: any;
  // カレンダーの選択範囲
  public stMinDate: Date;
  public stMaxDate: Date;
  public enMinDate: Date;
  public enMaxDate: Date;
  // Datepickerの初期値
  public bsConfig = Object.assign({},
    {showWeekNumbers: false,
      containerClass: 'theme-blue'});

// コンテンツのOption
public contents: any = Setting.contents;
// 対象のOption
public target: any = Setting.target;
// 実施場所のOption
public scene: any = Setting.scene;
// 活動成果のOption
public result: any = Setting.result;
// 人数のOption
public numberOfPeople: any = Setting.numberOfPeople;
// 表示しない販売店
public exceptShop: string[] = Setting.exception;

  constructor(
    private searchFilterService: SearchFilterService,
    private transferParams: TransferParamsService,
    private localeService: BsLocaleService,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.searchFilterService.getAdminQueryParams().subscribe(
      data => {
        this.contestList = data.con_list;
        this.areaList = data.area_list;
        this.groups = data.groups;
        // 支店代表には該当する販売店グループのみ表示
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentStatus = currentUser.identity_flag;
        if (this.currentStatus === '2') {
          this.areaGroups = this.groups;
    }

      },
      error => this.errorMessage = <any>error);

    // パラメータの初期値の取得
    this.postParams = this.transferParams.postAdminParams;
    // パラメータに変化があった時subscribe
    this.transferParams.paramsAdminData$.subscribe(
      (params) => {
        // 検索期間の終了日を1日減らす（検索時に増やしているので）
        if (this.postParams.en) {
          const endDate = new Date(this.postParams.en);
          this.postParams.en = this.datePipe.transform((endDate.setDate(endDate.getDate() - 1) + ""), 'yyyy/MM/dd');
        }
        this.postParams = params;
      });

    this.localeService.use('ja');
    this.stMinDate = new Date(Setting.startDate);
    this.stMaxDate = new Date();
    this.enMinDate = new Date(Setting.startDate);
    this.enMaxDate = new Date();
  }

  // 地域SelectBoxの初期値取得
  onChangeArea(areaId): void {
    this.postParams.group = '0';
    const currentArea = _.where(this.areaList, { id: +areaId });
    if (currentArea.length > 0) {
      this.areaGroups = this.areaFilter(currentArea[0].code);
      // aValueにない販売店を消去
      for ( const i in this.exceptShop) {
        this.areaGroups = this.areaGroups.filter((item: any) => {
            return item.group_name !== this.exceptShop[i];
        });
      }
    } else {
      this.areaGroups = [];
    }
  }
  private areaFilter(filterBy: string): any[] {
    return this.groups.filter((item: any) =>
      item.group_belong_code === filterBy || item.region_code === filterBy);
  }

  // 年度切り替え時にコンテストのSelectBoxを同期
  onChangeYear(year): void {
    this.searchFilterService.getAdminContests(+year).subscribe(
      data => {
        this.contestList = data.con_list;
      },
      error => this.errorMessage = <any>error);
  }

   // 投稿日での検索時に、MinDate、MaxDateを制限する
   onStChange(value: Date): void {
    this.enMinDate = value;
  }
  onEnChange(value: Date): void {
    this.stMaxDate = value;
  }

   // フリーキーワード内でENTERが押されたとき無視する
   enterDown() {
    return false;
  }

  // Submit
  submitForm(form: NgForm): void {
    this.transferParams.transferAdminParamsToCompo(this.postParams);
  }

  // Reset
  reset(): void {
    this.postParams.offset = '0';
    this.postParams.num = '12';
    this.postParams.sort = '3';
    this.postParams.th = '0';
    // this.postParams.th_opt = '0';
    this.postParams.con = '0';
    this.postParams.con_m = '0';
    this.postParams.ch = '0';
    this.postParams.ac = '0';
    this.postParams.co = '0';
    this.postParams.nu = '0';
    // this.postParams.st_en = ['', ''];
    this.postParams.st = '';
    this.postParams.en = '';
    this.postParams.keyword = '';
    this.postParams.group = '0';
    this.postParams.region = '0';
    this.postParams.year = '1';
    this.postParams.read = '0';
    this.postParams.user_id = '';
    this.transferParams.transferParamsToCompo(this.postParams);
  }
}
