import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '../../setting';
import * as _ from 'underscore';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform( value: any, cate: any): any {

    if ( cate === 'contents') {
      return _.invert(Setting.contents)[value];
    }
    if ( cate === 'target') {
      return _.invert(Setting.target)[value];
    }
    if ( cate === 'scene') {
      return _.invert(Setting.scene)[value];
    }
    if ( cate === 'numberOfPeople') {
      return _.invert(Setting.numberOfPeople)[value];
    }
    if ( cate === 'result') {
      return _.invert(Setting.result)[value];
    }
    return null;
  }

}
