import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AuthenticationService } from '../auth/auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    model: any = {};
    // loading = false;
    error = '';

    public currentUserId: number;
    public compoName: string = "login";

    public errorMessage: string;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) { }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();
    }

    login() {
        // const userName = 'u01@syncimage.com';
        // const password = 'password';
        const userName = this.model.userName;
        const password = this.model.password;
        this.authenticationService.login(userName, password)
            .subscribe(result => {
                if (result) {
                    if (result.identity_flag === "7") {
                        this.router.navigate(['/avalue-mypage/posts']);
                    } else {
                        this.router.navigate(['/avalue-mypage']);
                        // console.log("success");
                    }
                } else {
                    this.errorMessage = 'Username or password is incorrect';
                    // console.log("false1");
                }
            },
                (error) => {
                    this.errorMessage = <any>error;
                }
            );
    }
}
