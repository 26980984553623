import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TransferParamsService } from '../transfer-params.service';

import { IPostsParams } from '../posts/posts-params';

@Component({
  selector: 'app-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss']
})
export class SortFilterComponent implements OnInit {

  public postParams: IPostsParams;

  constructor(
    private transferParams: TransferParamsService
  ) { }

  ngOnInit() {
    // パラメータの初期値の取得
    this.postParams = this.transferParams.postParams;
    // パラメータのfilterに変化があった時subscribe
    this.transferParams.paramsData$.subscribe(
      (params) => {
        this.postParams = params;
      });
  }

  // Submit
  // submitForm(): void {
  //   this.transferParams.transferParamsToCompo(this.postParams);
  // }

  sortForm(event): void {
    this.postParams.sort = event.target.value;
    this.transferParams.transferParamsToCompo(this.postParams);
  }

  sortReadForm(event): void {
    this.postParams.read = event.target.value;
    this.transferParams.transferParamsToCompo(this.postParams);
  }

  setFavoriteForm(event): void {
    this.postParams.favorite = event.target.value;
    this.transferParams.transferParamsToCompo(this.postParams);

  }

}
