import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { DatePipe } from '@angular/common';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
// import { listLocales } from 'ngx-bootstrap/chronos';

import * as _ from 'underscore';

import { SearchFilterService } from './search-filter.service';
import { TransferParamsService } from '../transfer-params.service';

import { Setting } from '../../setting';

import { IPostsParams } from '../posts/posts-params';

import { AuthenticationService } from '../../auth/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {

  public errorMessage: string;
  public contestList: any[];
  public areaList: any[];
  public groups: any[];
  public areaGroups: any[] = [];
  public postParams: IPostsParams;
  // カレンダーの選択範囲
  public stMinDate: Date;
  public stMaxDate: Date;
  public enMinDate: Date;
  public enMaxDate: Date;
  // Datepickerの初期値
  public bsConfig = Object.assign({},
    {
      showWeekNumbers: false,
      containerClass: 'theme-blue'
    });

  // コンテンツのOption
   public contents: any = Setting.contents;
  // 対象のOption
  public target: any = Setting.target;
  // 実施場所のOption
  public scene: any = Setting.scene;
  // 活動成果のOption
  public result: any = Setting.result;
  // 人数のOption
  public numberOfPeople: any = Setting.numberOfPeople;
  // 表示しない販売店
  public exceptShop: string[] = Setting.exception;

  // KBVかを判断
  public kbvFlag: boolean = false;

   // 表示数
   public numDisplay: string = 'small';
   private displayNum: string = Setting.pageNum;

  // @Input() pageName: string;

  constructor(
    private searchFilterService: SearchFilterService,
    private transferParams: TransferParamsService,
    private localeService: BsLocaleService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    // ローカルストレージから表示の多少を取得
    const dispNum = localStorage.getItem('numberDisplay');
    if (dispNum) {
      this.numDisplay =  dispNum;
      (dispNum === 'small') ? this.displayNum = Setting.pageNum : this.displayNum = Setting.pageNumMuch;
    }

    this.searchFilterService.getQueryParams().subscribe(
      data => {
        this.contestList = data.con_list;
        this.areaList = data.area_list;
        this.groups = data.groups;
        // console.log(JSON.stringify(this.contestList));
      },
      error => this.errorMessage = <any>error);

    // パラメータの初期値の取得
    this.postParams = this.transferParams.postParams;

    // パラメータに変化があった時subscribe
    this.transferParams.paramsData$.subscribe(
      (params) => {
        this.postParams = params;
        this.onChangeArea(params.region);
      });

    this.localeService.use('ja');
    this.stMinDate = new Date(Setting.startDate);
    this.stMaxDate = new Date();
    this.enMinDate = new Date(Setting.startDate);
    this.enMaxDate = new Date();

    // KBV判定
    this.kbvFlag = this.authenticationService.kbvFlag;
  }

  // 地域SelectBoxの初期値取得
  onChangeArea(areaId): void {
    this.postParams.group = '0';
    const currentArea = _.where(this.areaList, { id: +areaId });
    if (currentArea.length > 0) {
      this.areaGroups = this.areaFilter(currentArea[0].code);
      // aValueにない販売店を消去
      for ( const i in this.exceptShop) {
        this.areaGroups = this.areaGroups.filter((item: any) => {
            return item.group_name !== this.exceptShop[i];
        });
      }
    } else {
      this.areaGroups = [];
    }
  }
  private areaFilter(filterBy: string): any[] {
    return this.groups.filter((item: any) =>
      item.group_belong_code === filterBy || item.region_code === filterBy);
  }

  // 年度切り替え時にコンテストのSelectBoxを同期
  onChangeYear(year): void {
    this.searchFilterService.getContests(+year).subscribe(
      data => {
        this.contestList = data.con_list;
      },
      error => this.errorMessage = <any>error);
  }

  // 投稿日での検索時に、MinDate、MaxDateを制限する
  onStChange(value: Date): void {
    this.enMinDate = value;
  }
  onEnChange(value: Date): void {
    this.stMaxDate = value;
  }

  // フリーキーワード内でENTERが押されたとき無視する
  enterDown() {
    return false;
  }

  // Submit
  submitForm(form: NgForm): void {
    // this.postParams.user_id = '';
    this.transferParams.transferParamsToCompo(this.postParams);
  }

  // Reset
  reset(): void {
    this.postParams.offset = '0';
    this.postParams.num = this.displayNum + '';
    console.log(this.displayNum);
    this.postParams.sort = '3';
    if (!this.kbvFlag) {
      this.postParams.th = '0';
    } else {
      this.postParams.th = '12';
    }
    // this.postParams.th_opt = '0';
    this.postParams.con = '0';
    this.postParams.con_m = '0';
    this.postParams.ch = '0';
    this.postParams.ac = '0';
    this.postParams.co = '0';
    this.postParams.nu = '0';
    // this.postParams.st_en = ['', ''];
    this.postParams.st = '';
    this.postParams.en = '';
    this.postParams.keyword = '';
    this.postParams.group = '0';
    this.postParams.region = '0';
    if (!this.kbvFlag) {
      this.postParams.year = '2';
    } else {
      this.postParams.year = '2';
    }
    this.postParams.read = '0';
    this.postParams.user_id = '';
    this.transferParams.transferParamsToCompo(this.postParams);
  }
}
